<template>
  <div class="roleInfo">
    <div class="actionBar">
      <a-input-search
        size="large"
        v-model="listParams.keyword"
        placeholder="请输入关键词搜索"
        enter-button="查询"
        style="width: 350px"
        allowClear
        @search="onSearch"
      />
      <a-button icon="plus" type="primary" @click="showModal" size="large"
      v-if="handLists.top.indexOf('add')!=-1"
      >
        添加分类</a-button
      >
    </div>
    <a-modal
      :title="modalTitle"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      okText="确定"
      cancelText="取消"
    >
      <a-form
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 16 }"
        :from="addFromData"
      >
        <a-form-item label="分类名称">
          <a-input v-model="addFromData.name" />
        </a-form-item>
        <a-form-item label="分类简介">
          <a-input v-model="addFromData.description" />
        </a-form-item>
        <a-form-item label="排序">
          <a-input-number v-model="addFromData.list_order" />
        </a-form-item>
        <a-form-item label="状态">
          <a-radio-group v-model="addFromData.status">
            <a-radio :value="1">
              显示
            </a-radio>
            <a-radio :value="0">
              隐藏
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="图片">
          <a-upload
            name="file"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :action="uploadImage"
            :before-upload="beforeUpload"
            @change="handleChange"
          >
            <img
              v-if="addFromData.thumb"
              :src="addFromData.thumb"
              style="display:block;max-width:200px;max-height:200px"
            />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
            </div>
          </a-upload>
        </a-form-item>
      </a-form>
    </a-modal>
    <a-table
      :columns="columns"
      :data-source="data"
      rowKey="id"
      :pagination="{
        total: count,
        current: listParams.page,
        pageSize: listParams.limit,
        showTotal: (total) => `共 ${total} 条`,
      }"
      @change="pageChange"
    >
      <span slot="list_order" slot-scope="text, record">
        <a-input-number
          :min="0"
          v-model="record.list_order"
          style="width:80px"
          @focus="saveOldNum(text)"
          @blur="listOrderChangeVal(record)"
        >
        </a-input-number>
      </span>

      <span slot="status" slot-scope="text">
        <a-tag color="#999" v-if="text == 0">
          隐藏
        </a-tag>
        <a-tag color="green" v-else-if="text == 1">
          显示
        </a-tag>
      </span>


      <span slot="action" slot-scope="text, record">
        <span v-if="handLists.right.indexOf('setStatus')!=-1">
             <a-switch
          checked-children="启"
          un-checked-children="禁"
          :checked="record.status == 1"
          @click="setStatus(record)"
        />
        <a-divider type="vertical" />
        </span>

        <span v-if="handLists.right.indexOf('edit')!=-1">
             <FontIcon
          @click.native="editMenu(record)"
          title="修改"
          type="icon-xiugai"
          size="22"
        ></FontIcon>
        <a-divider type="vertical" />
        </span>
     

        <a-popconfirm
          title="确定删除？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="confirm(record)"
          v-if="handLists.right.indexOf('del')!=-1"
        >
          <FontIcon title="删除" type="icon-lajitong" size="22"> </FontIcon>
        </a-popconfirm>
      </span>
    </a-table>
  </div>
</template>

<script>
import {
  categoryShowList,
  categoryIndex,
  categoryDataSave,
  categoryDelete,
  categorySetStatus,
  categoryChangeVal,
  uploadImage,
} from "@/apis/index";
const columns = [
  {
    title: "排序",
    dataIndex: "list_order",
    scopedSlots: { customRender: "list_order" },
    width: 160,
  },
  {
    title: "ID",
    dataIndex: "id",
    scopedSlots: { customRender: "id" },
  },
  {
    title: "分类名称",
    dataIndex: "name",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "描述",
    dataIndex: "description",
    scopedSlots: { customRender: "description" },
  },
  {
    title: "状态",
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  data() {
    return {
      uploadImage,
      columns,
      data: [],
      visible: false,
      confirmLoading: false,
      modalTitle: "",
      addFromData: {},
      listParams: {
        page: 1,
        limit: 9,
        keyword: "",
      },
      count: 0,
      autoExpandParent: true,

      loading: false,

      oldNum: "",
      handLists: this.$store.state.menuHandleList,
    };
  },
  computed: {
    handList() {
      return this.$store.state.menuHandleList;
    },
  },
  watch: {
    handList(val) {
      this.handLists.top = val.top ? val.top : [];
      this.handLists.right = val.right ? val.right : [];
    },
  },
  created() {
    this.getCategoryIndex();
  },
  methods: {
    upShowList(){
      categoryShowList().then((res) => {
      this.$store.commit("setCategoryShowList", res.data.list);
    });
    },

    saveOldNum(text) {
      this.oldNum = text;
    },
    listOrderChangeVal(record) {
      if (this.oldNum == record.list_order) {
        return false;
      } else {
        categoryChangeVal({
          id: record.id,
          change_value: record.list_order,
        }).then((res) => {
          if (res.code == 1) {
            this.$message.success("修改成功");
            this.getCategoryIndex();
            this.upShowList()
          }
        });
      }
    },
    setStatus(record) {
      categorySetStatus({
        ids: record.id,
        status: record.status == 1 ? 2 : 1,
      }).then((res) => {
        if (res.code == 1) {
          this.getCategoryIndex();
            this.upShowList()

        }
      });
    },

    showModal() {
      this.modalTitle = "新增分类";
      (this.addFromData = {
        id: "",
        name: "",
        description: "",
        status: 1,
        list_order: "",
        thumb: "",
      }),
        (this.visible = true);
    },
    handleOk(e) {
      this.confirmLoading = true;
      categoryDataSave(this.addFromData).then((res) => {
        if (res.code == 1) {
          this.getCategoryIndex();
            this.upShowList()

          this.visible = false;
        }
        this.confirmLoading = false;
      });
    },
    handleCancel(e) {
      this.visible = false;
    },
    editMenu(record) {
      this.modalTitle = "修改信息";
      this.addFromData = {
        id: record.id,
        name: record.name,
        description: record.description,
        status: record.status,
        list_order: record.list_order,
        thumb: record.thumb_info ? record.thumb_info.url : "",
      };

      this.visible = true;
    },
    confirm(rec) {
      categoryDelete({ id: rec.id }).then((res) => {
        if (res.code == 1) {
          this.$message.success("删除成功");
            this.upShowList()
          this.getCategoryIndex();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    pageChange(a) {
      this.listParams.page = a.current;
      this.getCategoryIndex();
    },
    getCategoryIndex() {
      categoryIndex(this.listParams).then((res) => {
        if (res.data.list.length == 0 && this.listParams.page > 1) {
          this.listParams.page--;
          this.getCategoryIndex();
        } else {
          this.data = res.data.list;
          this.count = res.data.count;
        }
      });
    },
    onSearch(value) {
      this.listParams.page = 1;
      this.getCategoryIndex();
    },

    handleChange(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        this.addFromData.thumb = info.file.response.data.url;
      }
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("Image must smaller than 2MB!");
      }
      return isJpgOrPng && isLt2M;
    },
  },
};
</script>

<style lang="less" scoped>
.roleInfo {
  .actionBar {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
  }
}
</style>
